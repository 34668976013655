//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import BaseModal from "~/components/modals/BaseModal.vue";
import RequestNewServiceForm from "~/components/profile/RequestNewServiceForm.vue";

export default {
  name: 'OfferServiceModal',

  components: {RequestNewServiceForm, BaseModal},

  props: {
    countrysList: {
      type: Array,
      defoult() {
        return [];
      },
    },
  },

  methods: {
    onCloseModal() {
      this.$modal.hide('offer-service');
    },
  },
}
